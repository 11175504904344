import notification from "antd/es/notification";
import {
  CLEAR_FORM_SUBMIT_ERROR,
  FORM_SUBMIT_ERROR,
  CLEAR_FORM_MESSAGE,
  ADD_FORM_MESSAGE,
  UPDATE_APP_ENVIRONMENT,
} from "./types";
import DataStore from "../dataStore";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import moment from "moment";
import constant from "../constants";

export const extractErrors = (error) => {
  if (!error.response) {
    return [];
  }

  if (error.response.data.errors && error.response.data.errors.length > 0) {
    return error.response.data.errors.map((error) => error.message);
  } else {
    return [error.response.data.responseMessage];
  }
};

export const showError = (response) => {
  const errors = extractErrors(response);

  if (errors && errors.length > 0) {
    if (errors[0] === constant.REQUEST_BEEN_PROCESS) {
      // showMessage("Request successful", errors[0], "info");
      return;
    }
    showMessage("Request Failed", errors[0], "error");
  }
};

export const showMessage = (title, description, type = "success") => {
  notification.open({
    type,
    message: title,
    description,
  });
};

export const displayError = (title, description, type = "error") => {
  notification.open({
    type,
    message: title,
    description,
  });
};

export const clearFormError = (form) => ({
  type: CLEAR_FORM_SUBMIT_ERROR,
  payload: {
    form,
  },
});

export const showFormError = (form, error) => ({
  type: FORM_SUBMIT_ERROR,
  payload: {
    form,
    error,
  },
});

export const addFormMessage = (form, message, type = "success") => ({
  type: ADD_FORM_MESSAGE,
  payload: {
    form,
    message,
    type,
  },
});

export const clearFormMessage = (form) => ({
  type: CLEAR_FORM_MESSAGE,
  payload: {
    form,
  },
});

export const setAppEnvironment = (isLive) => ({
  type: UPDATE_APP_ENVIRONMENT,
  payload: isLive,
});

export const saveVisitedRoutes = (path) => {
  const visitedRoutes = DataStore.get(DATA_STORE_KEYS.VISITED_PAGES) || {};
  const routeInfo = visitedRoutes[path] || { timesVisited: 0, path };

  visitedRoutes[path] = {
    ...routeInfo,
    timesVisited: routeInfo.timesVisited + 1,
    dateModified: Date.now(),
  };

  DataStore.save(DATA_STORE_KEYS.VISITED_PAGES, visitedRoutes);
};

export const removeExpiredVisitedRoutes = () => {
  const visitedRoutes = DataStore.get(DATA_STORE_KEYS.VISITED_PAGES);
  if (visitedRoutes) {
    const currentDate = Date.now();
    const activeRoutes = {};

    for (const key in visitedRoutes) {
      const { dateModified, path } = visitedRoutes[key];
      if (dateModified && path) {
        const dt = new Date(dateModified);
        const setDate = dt.setDate(dt.getDate() + 5);
        if (currentDate <= setDate) {
          activeRoutes[key] = visitedRoutes[key];
        }
      }
    }

    return DataStore.save(DATA_STORE_KEYS.VISITED_PAGES, activeRoutes);
  }
};

export const getMostVisitedRoute = () => {
  const visitedRoutes = DataStore.get(DATA_STORE_KEYS.VISITED_PAGES);
  let mostVisitedRoute = "";
  if (visitedRoutes) {
    const visitedRouteValues = Object.values(visitedRoutes);
    const visitedRoutesCounts = visitedRouteValues.map((route) => route.timesVisited);
    const mostVisited = Math.max.apply(Math, visitedRoutesCounts);
    const indexOfMostVisited = visitedRoutesCounts.indexOf(mostVisited);
    mostVisitedRoute = visitedRouteValues[indexOfMostVisited]?.path;
  }
  return mostVisitedRoute;
};

export const getOneDayDateRange = () => ({
  to: moment().format("x"),
  from: moment().startOf("day").format("x"),
});

export const extractRequestError = (error) => {
  if (error.request) {
    if (window.navigator.onLine) {
      return "Server currently offline.";
    } else {
      return "Network error. Please check your internet connection and try again.";
    }
  }
  return "";
};

export const showAllErrors = (error) => {
  const errorMessage = extractErrors(error)[0] || extractRequestError(error);
  if (errorMessage) {
    showMessage("Request Failed", errorMessage, "error");
  }
};
