/**
 * TO DO: IMPORT TYPES
 */

import {
  SAVE_DISBURSEMENT_WALLETS,
  SAVE_WALLET,
  SAVE_WALLET_DETAIL,
  SAVE_DISBURSEMENT_TRANSACTIONS,
  UPDATE_DISBURSEMENT_TRANSACTIONS_LOADING,
  UPDATE_DISBURSEMENTS_DETAIL_LOADING,
  SAVE_WALLET_TOP_UP_DETAIL,
  SAVE_WALLET_TRANSACTIONS,
  UPDATE_WALLET_TRANSACTIONS_LOADING,
  SAVE_DISBURSEMENT_TRANSACTION_DETAIL,
  UPDATE_DISBURSEMENT_TRANSACTIONS_DETAIL_LOADING,
  IS_DISBURSEMENT_FILTER,
  UPDATE_TRANSFER_BENEFICIARY_LOADING,
  SAVE_TRANSFER_BENEFICIARY,
  SAVE_TRANSFER_DETAILS_SUMMARY,
  SAVE_BULK_UPLOAD_DETAILS,
  SAVE_BULK_TRANSACTIONS,
  UPDATE_BULK_TRANSACTIONS_LOADING,
  SAVE_BULK_TRANSACTION_DETAILS,
  SAVE_ALL_WALLET,
} from "../actions/types";

import _ from "lodash";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import DataStore from "../dataStore";

const INIT_STATE = {
  loading: false,
  loadingTopUp: false,
  loadingDisbursementTransactions: false,
  loadingWalletTransactions: false,
  loadingDisbursementTransactionsDetails: false,
  loadingTransferBeneficiaries: false,
  data: {
    test: [],
    live: [],
  },
  walletDetailData: {
    test: [],
    live: [],
  },
  disbursementTransactionDetail: {
    test: [],
    live: [],
  },
  disbursementTransactionData: {
    test: [],
    live: [],
  },
  walletTransactionData: {
    test: [],
    live: [],
  },
  wallets: {
    test: [],
    live: [],
  },
  topUpData: {
    test: [],
    live: [],
  },
  lastPageRequest: {},
  pagination: {
    live: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
    test: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },

  disbursementData: {
    details: {},
    transactions: [],
    loadingTransactions: false,
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
    },
  },
  isDisbursementFilter: {
    test: false,
    live: false,
  },
  transferBeneficiaries: {
    test: [],
    live: [],
  },
  transferDetailsSummary: {
    test: [],
    live: [],
  },
  bulkUploadDetails: {},
  bulkTransactions: {
    test: [],
    live: [],
  },
  loadingBulkTransactions: false,
  bulkTransactionDetails: {
    test: [],
    live: [],
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_DISBURSEMENT_WALLETS: {
      const pagination = _.cloneDeep(state.pagination);
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.environment]: action.payload.data.data,
        },
        pagination,
      };
    }

    case SAVE_WALLET:
      DataStore.save(DATA_STORE_KEYS.ACCOUNT_NO, {
        [action.payload.environment]: action.payload.data.accountNumber,
      });

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.environment]: action.payload.data,
        },
      };
    case SAVE_ALL_WALLET:
      return {
        ...state,
        wallets: {
          [action.payload.environment]: action.payload.data,
        },
      };

    case SAVE_WALLET_DETAIL:
      return {
        ...state,
        walletDetailData: {
          [action.payload.environment]: action.payload.data,
        },
      };
    case SAVE_DISBURSEMENT_TRANSACTION_DETAIL:
      return {
        ...state,
        disbursementTransactionDetail: {
          [action.payload.environment]: action.payload.data,
        },
      };
    case SAVE_WALLET_TOP_UP_DETAIL:
      return {
        ...state,
        topUpData: {
          [action.payload.environment]: action.payload.data,
        },
      };

    case SAVE_DISBURSEMENT_TRANSACTIONS:
      return {
        ...state,
        disbursementTransactionData: {
          [action.payload.environment]: action.payload.data,
        },
      };
    case SAVE_WALLET_TRANSACTIONS:
      return {
        ...state,
        walletTransactionData: {
          [action.payload.environment]: action.payload.data,
        },
      };

    case UPDATE_DISBURSEMENT_TRANSACTIONS_LOADING:
      return {
        ...state,
        loadingDisbursementTransactions: action.payload,
      };
    case UPDATE_DISBURSEMENT_TRANSACTIONS_DETAIL_LOADING:
      return {
        ...state,
        loadingDisbursementTransactionsDetails: action.payload,
      };
    case UPDATE_DISBURSEMENTS_DETAIL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_WALLET_TRANSACTIONS_LOADING:
      return {
        ...state,
        loadingWalletTransactions: action.payload,
      };

    case IS_DISBURSEMENT_FILTER: {
      const { isDisbursementFilter } = state;
      isDisbursementFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isDisbursementFilter,
      };
    }
    case UPDATE_TRANSFER_BENEFICIARY_LOADING:
      return {
        ...state,
        loadingTransferBeneficiaries: action.payload,
      };
    case SAVE_TRANSFER_BENEFICIARY: {
      const pagination = _.cloneDeep(state.pagination);
      pagination[action.payload.environment] = action.payload.data.pagination;

      return {
        ...state,
        transferBeneficiaries: {
          [action.payload.environment]: action.payload.data,
        },
        pagination,
      };
    }
    case SAVE_TRANSFER_DETAILS_SUMMARY: {
      return {
        ...state,
        transferDetailsSummary: {
          [action.payload.environment]: action.payload.data,
        },
      };
    }
    case SAVE_BULK_UPLOAD_DETAILS: {
      return {
        ...state,
        bulkUploadDetails: action.payload,
      };
    }
    case SAVE_BULK_TRANSACTIONS: {
      return {
        ...state,
        bulkTransactions: {
          [action.payload.environment]: action.payload.data,
        },
      };
    }
    case SAVE_BULK_TRANSACTION_DETAILS: {
      return {
        ...state,
        bulkTransactionDetails: {
          [action.payload.environment]: action.payload.data,
        },
      };
    }
    case UPDATE_BULK_TRANSACTIONS_LOADING:
      return {
        ...state,
        loadingBulkTransactions: action.payload,
      };
    default:
      return state;
  }
};
